/* global showOverlay, hideOverlay */

// Set constants for the DOM nodes
const headerMobileBar = document.querySelector( '#header-mobile-bar' );
const mobileMenuToggle = document.querySelector( '#header-mobile-menu-toggle' );
const mobileMenuWrapper = document.querySelector( '#mobile-menu-wrapper' );

function activateMenu() {

	// Show the overlay
	showOverlay( 'mobile-overlay', 50 );

	// Show the mobile menu
	mobileMenuWrapper.style.maxHeight = ( window.innerHeight - headerMobileBar.clientHeight ) + 'px';
	mobileMenuWrapper.style.opacity = '0';
	mobileMenuWrapper.style.height = 'auto';
	let mobileMenuWrapperHeight = mobileMenuWrapper.clientHeight + 'px';
	mobileMenuWrapper.style.height = '0';
	mobileMenuWrapper.style.opacity = '1';
	mobileMenuWrapper.animate(
		[
			{ height: '0', easing: 'ease-out' },
			{ height: mobileMenuWrapperHeight, easing: 'ease-out' },
		],
		300
	);
	mobileMenuWrapper.style.height = 'auto';

	// Set the aria-expanded attribute to true
	mobileMenuToggle.setAttribute( 'aria-expanded', 'true' );

	// Update the aria label
	mobileMenuToggle.setAttribute(
		'aria-label',
		mobileMenuToggle.getAttribute( 'aria-label' ).replace( 'Activate', 'Deactivate' )
	);

	// Add event listeners to close menu on click
	document.querySelectorAll( '#mobile-menu a' ).forEach( function ( element ) {
		element.addEventListener( 'click', function ( event ) {
			event.preventDefault();
			mobileMenuWrapper.style.height = '0';
			mobileMenuToggle.setAttribute( 'aria-expanded', 'false' );
			mobileMenuToggle.setAttribute(
				'aria-label',
				mobileMenuToggle.getAttribute( 'aria-label' ).replace( 'Deactivate', 'Activate' )
			);
			document.body.removeChild( document.querySelector( '.mobile-overlay' ) );
			window.location = event.currentTarget.getAttribute( 'href' );
		} );
	} );
}

function deactivateMenu() {
	// Hide the mobile menu
	let mobileMenuWrapperHeight = mobileMenuWrapper.clientHeight + 'px';
	mobileMenuWrapper.animate(
		[
			{ height: mobileMenuWrapperHeight, easing: 'ease-out' },
			{ height: '0', easing: 'ease-out' },
		],
		300
	);
	mobileMenuWrapper.style.height = '0';

	// Set the aria-expanded attribute to true
	// The menu visibility is controlled by this attribute in CSS
	mobileMenuToggle.setAttribute( 'aria-expanded', 'false' );

	// Update the aria label
	mobileMenuToggle.setAttribute(
		'aria-label',
		mobileMenuToggle.getAttribute( 'aria-label' ).replace( 'Deactivate', 'Activate' )
	);

	// Hide the overlay
	hideOverlay( 'mobile-overlay' );
}

// Only try to do this if the mobile menu toggle exists
if ( mobileMenuToggle ) {

	// Enable menu toggle
	mobileMenuToggle.addEventListener( 'click', function ( event ) {
		// If the menu isn't open yet
		if ( mobileMenuToggle.getAttribute( 'aria-expanded' ) === 'false' ) {

			activateMenu();

		// Otherwise the menu is already open
		} else {

			deactivateMenu();

		}
	} );

}
