// Get filter form
const filterForm = document.querySelector( '#biro-filters-form' );

// Get all filters
const filterSelects = document.querySelectorAll( '#biro-filters-form .select' );

// For each filter
filterSelects.forEach( function ( filterSelect ) {

	// Listen for a change
	filterSelect.addEventListener( 'change', function ( event ) {

		// Mark this filter as submitting
		event.currentTarget.classList.add( 'is-submitting' );

		// Loop through the filters
		filterSelects.forEach( function ( filterSelect ) {

			// If it's not the changed one
			if ( filterSelect !== event.currentTarget ) {

				// Reset the value
				filterSelect.selectedIndex = 0;

				// Remove the is-active class
				filterSelect.classList.remove( 'is-active' );

			}

		} );

		// Start constructing the new URL
		let newUrl = window.location.origin + window.location.pathname;

		// If the filter has a value
		if ( event.currentTarget.value > 0 ) {

			// Add the filter query string
			newUrl += '?' + event.currentTarget.getAttribute( 'name' ) + '=' + event.currentTarget.value;

		}

		// Add the filter form ID
		newUrl += '#biro-filters-form';

		// Go to the new URL
		window.location.href = newUrl;

	} );
} );
