/**
 * Show the ovelerlay
 *
 * @param {string} overlayClass - The unique class to give to the overlay
 * @param {string} zindex - Level at which to show the overlay
 *
 */
function showOverlay( overlayClass, zindex ) {
	// Create the DOM node
	let biroOverlay = document.createElement( 'div' );

	// Set the classes
	biroOverlay.setAttribute(
		'class',
		'biro-overlay ' + overlayClass
	);

	// Set the z-index
	biroOverlay.style.zIndex = zindex;

	// Set the opacity
	biroOverlay.style.opacity = '0';

	// Display the overlay
	document.body.appendChild( biroOverlay );

	// Fade in the overlay
	biroOverlay.animate(
		[
			{ opacity: '0', easing: 'ease-out' },
			{ opacity: '0.5', easing: 'ease-out' },
		],
		300
	);
	biroOverlay.style.opacity = '0.5';
}

/**
 * Hide the overlay
 *
 * @param {string} overlayClass - The unique class over the overlay to hide
 *
 */
function hideOverlay( overlayClass ) {
	// Get the DOM node
	let biroOverlay = document.querySelector( '.' + overlayClass );

	// Fade out the overlay
	biroOverlay.animate(
		[
			{ opacity: '0.5', easing: 'ease-out' },
			{ opacity: '0', easing: 'ease-out' },
		],
		300
	);
	biroOverlay.style.opacity = '0';

	// Remove the overlay
	setTimeout( function () {
		document.body.removeChild( biroOverlay );
	}, 300 );
}
